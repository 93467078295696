import React from 'react';
import { observer } from 'mobx-react-lite';
import { useInfiniteAPI } from 'polotno/utils/use-api';
import { ImagesGrid } from 'polotno/side-panel/images-grid';
import { SectionTab } from 'polotno/side-panel';
import ImInsertTemplate from '@meronex/icons/im/ImInsertTemplate';

const baseUrl = "https://presselocale.ensci.com/server/";

export const TemplatesPanel = observer(({ store }) => {
	// load data
	const { data, isLoading } = useInfiniteAPI({
		getAPI: ({ page }) => `${baseUrl}?list=templates&page=${page}`,
	});

	return (
		<div style={{height: '100%' }}>
			<ImagesGrid
				shadowEnabled={false}
				images={data?.map((data) => data.items).flat()}
				getPreview={(item) => `${item.preview}`}
				isLoading={isLoading}
				onSelect={async (item) => {
					// download selected json
					const req  = await fetch(`${item.json}`);
					const json = await req.json();
					// just inject it into store
					store.loadJSON(json);
				}}
				rowsNumber={2}
			/>
		</div>
	);
});

// define the new custom section
export const TemplatesSection = {
	name: 'custom-templates',
	Tab: (props) => (
		<SectionTab name="Modèles" {...props}>
		<ImInsertTemplate icon="new-text-box" />
		</SectionTab>
	),
	// we need observer to update component automatically on any store changes
	Panel: TemplatesPanel,
};
