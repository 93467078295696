import React from 'react';
import { observer } from 'mobx-react-lite';
import { InputGroup, Button } from '@blueprintjs/core';
import { useInfiniteAPI } from 'polotno/utils/use-api';
import { ImagesGrid } from 'polotno/side-panel/images-grid';
import { SectionTab } from 'polotno/side-panel';
import BiText from '@meronex/icons/bi/BiText';

const baseUrl = "https://presselocale.ensci.com/server/";
//onClick={handleGenerate}

export const TextPanel = observer(({ store }) => {
	// load data
	const { data, isLoading } = useInfiniteAPI({
		getAPI: ({ page }) => `${baseUrl}?list=texts&page=${page}`,
	});

	return (
		<div style={{height: '100%' }}>
			<ImagesGrid
				shadowEnabled={false}
				images={data?.map((data) => data.items).flat()}
				getPreview={(item) => `${item.preview}`}
				isLoading={isLoading}
				onSelect={async (item) => {
					// download selected json
					const req  = await fetch(`${item.json}`);
					const json = await req.json();
					let elements = [];
					// just inject it into store
					for(let i = 0 ; i < json.pages.length ; i++) {
						for(let k = 0 ; k < json.pages[i].children.length ; k++) {
							let element = json.pages[i].children[k];
							element.id = element.id+Math.random();
							store.activePage?.addElement(element);
							elements.push(element.id);
						}
					}
					store.selectElements(elements);
				}}
				rowsNumber={2}
			/>
		</div>
	);
});

// define the new custom section
export const TextSection = {
	name: 'custom-text',
	Tab: (props) => (
		<SectionTab name="Texte" {...props}>
		<BiText icon="new-text-box" />
		</SectionTab>
	),
	// we need observer to update component automatically on any store changes
	Panel: TextPanel,
};
