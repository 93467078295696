import React from 'react';
import { observer } from 'mobx-react-lite';
import { SectionTab } from 'polotno/side-panel';
import QRCode from 'qrcode';
import * as svg from 'polotno/utils/svg';
import ImQrcode from '@meronex/icons/im/ImQrcode';
import { Button, InputGroup } from '@blueprintjs/core';

// create svg image for QR code for input text
export async function getQR(text) {
	return new Promise((resolve) => {
		QRCode.toString(
			text || 'no-data',
			{
				type: 'svg',
				color: {
					dark: '#000', // Blue dots
					light: '#0000', // Transparent background
				},
			},
			(err, string) => {
				resolve(svg.svgToURL(string));
			}
		);
	});
}

// define the new custom section
export const QrSection = {
  name: 'qr',
  Tab: (props) => (
    <SectionTab name="QR-Code" {...props}>
      <ImQrcode />
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: observer(({ store }) => {
    const [val, setVal] = React.useState('');

    const el = store.selectedElements[0];
    const isQR = el?.name === 'qr';

    // if selection is changed we need to update input value
    React.useEffect(() => {
		/*
      if (el?.custom.value) {
        setVal(el?.custom.value);
      }
		*/
    }, [isQR, el]);

    // update image src when we change input data
    React.useEffect(() => {
      if (isQR) {
        getQR(val).then((src) => {
          el.set({
            src,
            custom: {
              value: val,
            },
          });
        });
      }
    }, [el, val, isQR]);

    return (
      <div>
        {isQR && <p>Modifier le QR-Code&nbsp;:</p>}
        {!isQR && <p>Créer un nouveau QR-Code&nbsp;:</p>}
        <InputGroup
          onChange={(e) => {
            setVal(e.target.value);
          }}
          placeholder="Lien vers un site web, adresse email…"
          value={val}
          style={{ width: '100%' }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '20px',
          }}
        >
          <Button
            style={{
              display: isQR ? '' : 'none',
            }}
            onClick={() => {
              store.selectElements([]);
              setVal('');
            }}
          >
            Déselectionner
          </Button>
          <Button
            style={{
              display: isQR ? 'none' : '',
            }}
            onClick={async () => {
              const src = await getQR(val);

              store.activePage.addElement({
                type: 'svg',
                name: 'qr',
                x: 50,
                y: 50,
                width: 200,
                height: 200,
                src,
                custom: {
                  value: val,
                },
              });
            }}
          >
            Générer le QR-Code
          </Button>
        </div>
      </div>
    );
  }),
};
