import React from 'react';
import ReactDOM from 'react-dom/client';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { SidePanel, PhotosSection, ElementsSection, UploadSection, BackgroundSection } from 'polotno/side-panel';
import { Workspace } from 'polotno/canvas/workspace';
import { createStore } from 'polotno/model/store';
import { QrSection } from './qr-section';
import { IconsSection } from './icons-section';
import { StableDiffusionSection } from './stable-diffusion-section';
import { TextSection } from './text-section';
import { TemplatesSection } from './templates-section';
import { setTranslations } from 'polotno/config';
import { addGlobalFont } from 'polotno/config';
import { getTranslations } from 'polotno/config';

import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
//https://icons.meronex.com/search

//API
/*
setAPI('iconscoutDownload', (id) => {
  return `https://example.com/download-iconscout?uuid=${id}`;
});
*/
setTranslations({
	"toolbar": {
		"opacity": "Opacité",
		"effects": "Effets",
		"blur": "Flou",
		"brightness": "Luminosité",
		"sepia": "Sépia",
		"grayscale": "Niveaux de gris",
		"textStroke": "Épaisseur du texte",
		"shadow": "Ombre",
		"border": "Bordure",
		"cornerRadius": "Rayon d’angle",
		"position": "Position",
		"layering": "Superposition",
		"toForward": "Au premier plan",
		"up": "Haut",
		"down": "Bas",
		"toBottom": "À l’arrière plan",
		"alignLeft": "Aligner à gauche",
		"alignCenter": "Aligner au centre",
		"alignRight": "Aligner à droite",
		"alignTop": "Aligner en haut",
		"alignMiddle": "Aligner au milieu",
		"alignBottom": "Aligner en bas",
		"flip": "Retourner",
		"flipHorizontally": "Retourner horizontalement",
		"flipVertically": "Retourner verticalement",
		"fitToBackground": "Ajuster à la page",
		"removeBackground": "Supprimer l’arrière-plan",
		"cancelRemoveBackground": "Annuler",
		"confirmRemoveBackground": "Confirmer",
		"crop": "Rogner",
		"cropDone": "Fait",
		"cropCancel": "Annuler",
		"removeClip": "Supprimer le découpage",
		"removeMask": "Supprimer le masque",
		"transparency": "Transparence",
		"lockedDescription": "L’objet est verrouillé. Déverrouillez-le pour autoriser les modifications depuis le canevas.",
		"unlockedDescription": "L’objet est déverrouillé. Verrouillez-le pour empêcher les modifications du canevas.",
		"removeElements": "Supprimer les éléments",
		"duplicateElements": "Dupliquer les éléments",
		"download": "Télécharger",
		"saveAsImage": "Enregistrer en tant qu’image",
		"saveAsPDF": "Enregistrer en PDF",
		"selectable": "Sélectionnable",
		"draggable": "Déplaçable",
		"contentEditable": "Peut modifier le contenu",
		"styleEditable": "Peut changer le style",
		"alwaysOnTop": "Toujours au premier-plan",
		"showInExport": "Afficher dans l’exportation"
	},
	"workspace": {
		"noPages": "Il n’y a pas encore de pages…",
		"addPage": "Ajouter une page",
		"removePage": "Supprimer la page",
		"duplicatePage": "Dupliquer la page",
		"moveUp": "Déplacer en haut",
		"moveDown": "Déplacer en bas"
	},
	"scale": {
		"reset": "Réinitialiser"
	},
	"error": {
		"removeBackground": "Oups ! Quelque chose s’est mal passé. L’arrière-plan ne peut pas être supprimé."
	},
	"sidePanel": {
		"templates": "Modèles",
		"searchTemplatesWithSameSize": "Afficher les modèles de même taille",
		"searchPlaceholder": "Rechercher…",
		"otherFormats": "Autres formats",
		"noResults": "Aucun résultat",
		"error": "Le chargement a échoué…",
		"text": "Texte",
		"uploadFont": "Télécharger une police",
		"myFonts": "Mes polices",
		"photos": "Photos",
		"elements": "Formes",
		"upload": "Télécharger",
		"uploadImage": "Choisir mes fichiers à télécharger",
		"uploadTip": "Sélectionne un ou plusieurs fichiers à télécharger :",
		"background": "Arrière-plan",
		"resize": "Redimensionner",
		"layers": "Calques",
		"layersTip": "Éléments sur la page active :",
		"noLayers": "Aucun élément sur la page…",
		"namePlaceholder": "Tapez le nom de l’élément…",
		"useMagicResize": "Utiliser le redimensionnement automatique",
		"width": "Largeur",
		"height": "Hauteur",
		"magicResizeDescription": "Le redimensionnement automatique redimensionnera et déplacera tous les éléments sur le canevas",
		"headerText": "Entête",
		"createHeader": "Créer un en-tête",
		"subHeaderText": "Sous-en-tête",
		"createSubHeader": "Créer un sous-en-tête",
		"bodyText": "Corps du texte",
		"createBody": "Créer un corps de texte"
	}
});

const store = createStore({key: 'MrORjcyNlvvUggJ8_UIZ', showCredit: true});
store.addPage();
addGlobalFont({
	fontFamily: 'ENSCI • ATELIER',
	styles: [
		{
			src: 'url(https://ressources.ensci.com/css/fonts/Atelier-Regular.ttf)',
			fontStyle: 'normal',
			fontWeight: 'normal',
		}
	]
});
addGlobalFont({
	fontFamily: 'ENSCI • Maison Neue Light',
	styles: [
		{
			src: 'url(https://ressources.ensci.com/css/fonts/MaisonNeue-Light.ttf)',
			fontWeight: 'light',
			fontStyle: 'normal'
		},
		{
			src: 'url(https://ressources.ensci.com/css/fonts/MaisonNeue-LightItalic.ttf)',
			fontWeight: 'light',
			fontStyle: 'italic'
		}
	]
});
addGlobalFont({
	fontFamily: 'ENSCI • Maison Neue Book',
	styles: [
		{
			src: 'url(https://ressources.ensci.com/css/fonts/MaisonNeue-Book.ttf)',
			fontWeight: 'normal',
			fontStyle: 'normal'
		},
		{
			src: 'url(https://ressources.ensci.com/css/fonts/MaisonNeue-BookItalic.ttf)',
			fontWeight: 'normal',
			fontStyle: 'italic'
		},
	]
});
addGlobalFont({
	fontFamily: 'ENSCI • Maison Neue Medium',
	styles: [
		{
			src: 'url(https://ressources.ensci.com/css/fonts/MaisonNeue-Medium.ttf)',
			fontWeight: 'medium',
			fontStyle: 'normal'
		},
		{
			src: 'url(https://ressources.ensci.com/css/fonts/MaisonNeue-MediumItalic.ttf)',
			fontWeight: 'medium',
			fontStyle: 'italic'
		}
	]
});
addGlobalFont({
	fontFamily: 'ENSCI • Maison Neue Demi',
	styles: [
		{
			src: 'url(https://ressources.ensci.com/css/fonts/MaisonNeue-Demi.ttf)',
			fontWeight: 'normal',
			fontStyle: 'normal'
		},
		{
			src: 'url(https://ressources.ensci.com/css/fonts/MaisonNeue-DemiItalic.ttf)',
			fontWeight: 'normal',
			fontStyle: 'italic'
		}
	]
});
addGlobalFont({
	fontFamily: 'ENSCI • Maison Neue Bold',
	styles: [
		{
			src: 'url(https://ressources.ensci.com/css/fonts/MaisonNeue-Bold.ttf)',
			fontWeight: 'bold',
			fontStyle: 'normal'
		},
		{
			src: 'url(https://ressources.ensci.com/css/fonts/MaisonNeue-BoldItalic.ttf)',
			fontWeight: 'bold',
			fontStyle: 'italic'
		}
	]
});
addGlobalFont({
	fontFamily: 'ENSCI • Maison Neue Mono',
	styles: [
		{
			src: 'url(https://ressources.ensci.com/css/fonts/MaisonNeue-Mono.ttf)',
			fontWeight: 'normal',
			fontStyle: 'normal'
		},
		{
			src: 'url(https://ressources.ensci.com/css/fonts/MaisonNeue-MonoItalic.ttf)',
			fontWeight: 'normal',
			fontStyle: 'italic'
		}
	]
});

const sections = [TemplatesSection,BackgroundSection,TextSection,IconsSection,PhotosSection,UploadSection,ElementsSection,QrSection,StableDiffusionSection];
export const App = ({ store }) => {
	return (
		<PolotnoContainer style={{width: '100%', height: '100%'}}>
			<SidePanelWrap>
				<SidePanel store={store} sections={sections} defaultSection="custom-templates" />
			</SidePanelWrap>
			<WorkspaceWrap>
				<Toolbar store={store} />
				<Workspace store={store} pageControlsEnabled={false} />
				<ZoomButtons store={store} />
			</WorkspaceWrap>
		</PolotnoContainer>
	);
};

let storeChanged = false;
store.setSize(480, 800, true);
store.on('change', () => {
	store.waitLoading();
	storeChanged = true;
});


const root = ReactDOM.createRoot(document.getElementById('editor'));
root.render(<App store={store} />);

//Fonction navette avec le JS
function preview(force) {
	if((storeChanged) || (force)) {
		console.log("Refresh");
		store.toDataURL().then((imageData) => {
			window.preview(imageData, store.toJSON());
			storeChanged = false;
		});
	}
}
setInterval(preview, 2000);
setTimeout(preview, 500);

function whatsup() {
	let retour = window.whatsup();
	if(retour) {
		if(retour.load) {
			load(retour.load);
			window.loadCallback(retour.load);
		}
		if(retour.preview)
			preview(true);
	}
}
setInterval(whatsup, 100);

function load(json) {
	console.log("Chargement", json);
	store.loadJSON(json);
}